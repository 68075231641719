<template>
  <div>
    <head-layout
      :head-title="$t('OfflinedataCollection.name')"
      :head-btn-options="headBtnOptions"
      @head-save="saveAll('save')"
      @head-save-back="saveAll('back')"
      @head-back="toback"
    >
    </head-layout>
    <div class="newZtFormBox" style="background-color: white">
      <el-form
        ref="step1Form"
        :rules="rules1"
        v-show="stepsActive == 0"
        :model="addform"
        label-width="120px"
      >
        <div style="height: 10px"></div>

        <el-collapse v-model="activeNames" style="margintop: 10px">
          <el-collapse-item name="1">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t('cip.dc.offlineDComission.yuaninfo') }}
              </i>
            </template>

            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.KFDComission.name')" prop="name">
                  <el-input v-model="addform.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.offlineDComission.sjkid')" prop="readerDatasourceId">
                  <el-select
                    filterable
                    @change="dbChangeFn"
                    v-model="addform.readerDatasourceId"
                    :placeholder="
                      $t('OfflinedataCollection.label.Databasename')
                    "
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in dbList"
                      :key="index"
                      :label="item.desc"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.offlineDComission.bmid')" prop="readerTable">
                  <el-select
                    filterable
                    v-model="addform.readerTable"
                    @change="getColumnFn"
                    :placeholder="
                      $t('OfflinedataCollection.label.Datatablename')
                    "
                    :error="$t('OfflinedataCollection.label.Datatablename')"
                  >
                    <el-option
                      v-for="(item, index) in biaoList"
                      :key="item.table_name"
                      :label="item.show_name"
                      :value="item.table_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFeild')">
                  <el-input v-model="addform.incrTimeFeild"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.DComission.startTime')">
                  <el-date-picker
                    clearable
                    v-model="addform.incrStartTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    :placeholder="$t('cip.dc.offlineDComission.chooseTime')"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFormat')">
                  <el-select
                    filterable
                    clearable
                    v-model="addform.incrTimeFormat"
                    :placeholder="$t('cip.dc.offlineDComission.incrTimeFormat')"
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in TimeTypeList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" v-show="addform.incrTimeFormat == 1">
                <el-form-item :label="$t('cip.dc.offlineDComission.incrTimeFormat2')">
                  <el-input v-model="addform.incrTimeFormat2"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="$t('cip.dc.CJudgmentTask.field.CycleSetting')"
                  prop="cron"
                >
                  <el-input readonly :value="addform.cron"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-button-group>
                  <el-button @click="cronDialogVisible = true">{{
                    $t("datacenter.dataQuality.monitoringTask.select")
                  }}</el-button>
                  <el-button @click="changeSee">{{
                    seeText
                  }}</el-button>
                </el-button-group>
              </el-col>
            </el-row>

             <el-form-item :label="$t('cip.dc.DComission.tenRun')" v-show="seeTen">
              <el-row>
                <el-col span="6" v-for="(item, index) in timeList" :key="index">
                  <div>{{ item }}</div>
                </el-col>
              </el-row>
            </el-form-item>

            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.SQLstatement')"
                >
                  <el-input
                    v-model="addform.rdbmsReaderBo.querySql"
                    type="textarea"
                    :rows="10"
                  ></el-input>

                  <el-button
                    type="primary"
                    :disabled="
                      !this.addform.rdbmsReaderBo.querySql ||
                      !this.addform.readerDatasourceId
                    "
                    @click="getColumnsByQuerySqlFn"
                    >{{
                      $t("OfflinedataCollection.label.Resolvefield")
                    }}</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>


          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                {{ $t('cip.dc.offlineDComission.mbinfo') }}
              </i>
            </template>

            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.Databasename')"
                  prop="writerDatasourceId"
                >
                  <el-select
                    filterable
                    @change="dbChangeFn1"
                    v-model="addform.writerDatasourceId"
                    :placeholder="
                      $t('OfflinedataCollection.label.Databasename')
                    "
                    :error="$t('OfflinedataCollection.label.Databasename')"
                  >
                    <el-option
                      v-for="(item, index) in dbList"
                      :key="index"
                      :label="item.desc"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('OfflinedataCollection.label.Datatablename')"
                  prop="writerTable"
                >
                  <el-select
                    filterable
                    v-model="addform.writerTable"
                    @change="getColumnFn1"
                    :placeholder="
                      $t('OfflinedataCollection.label.Datatablename')
                    "
                    :error="$t('OfflinedataCollection.label.Datatablename')"
                  >
                    <el-option
                      v-for="(item, index) in biaoList1"
                      :key="index"
                      :label="item.table_name"
                      :value="item.table_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
            </el-row>

            <!-- <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="
                    $t('OfflinedataCollection.label.PrecedingSQLstatement')
                  "
                >
                  <el-input
                    v-model="addform.rdbmsWriterBo.preSql"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="后置SQL语句:">
                  <el-input
                    v-model="addform.rdbmsWriterBo.postSql"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
          </el-collapse-item>

          <el-collapse-item name="3">
            <template slot="title">
              <i
                class="avue-group__title avue-group__header"
                style="
                  color: #999999 !important;
                  font-style: normal;
                  padding: 0 10px;
                "
                ><i
                  class="el-icon-document"
                  style="font-size: 20px; padding: 0 10px"
                ></i>
                SQL
              </i>
            </template>

            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="
                    $t('OfflinedataCollection.label.PrecedingSQLstatement')
                  "
                >
                  <el-input
                    v-model="addform.rdbmsWriterBo.preSql"
                    type="textarea"
                    :rows="10"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('cip.dc.offlineDComission.postSql')">
                  <el-input
                    v-model="addform.rdbmsWriterBo.postSql"
                    type="textarea"
                    :rows="10"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>

        <el-tabs type="border-card">
          <el-tab-pane
            :label="$t('cip.dc.offlineDComission.yuan') + $t('cip.dc.dataobject.field.saveField')"
          >
            <el-form-item label-width="1">
              <el-table
                @select="selectstep0Savetable"
                ref="step0Savetable"
                :data="colmnList"
                border
                size="small"
                style="width: 100%"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  :label="$t('cip.dc.dataobject.field.fieldName')"
                  prop="name"
                >
                </el-table-column>

                <el-table-column :label="$t('cip.dc.offlineDComission.filedzs')" prop="common">
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('cip.dc.offlineDComission.yuan') + $t('cip.dc.dataobject.field.ConditionField')"
          >
            <el-form-item label-width="1">
              <el-table
                ref="step0Tjtable"
                :data="colmnList"
                border
                size="small"
                style="width: 100%"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  :label="$t('cip.dc.dataobject.field.fieldName')"
                  prop="name"
                  width="180"
                >
                </el-table-column>

                <el-table-column :label="$t('cip.dc.offlineDComission.filedzs')" prop="common">
                </el-table-column>

                <!-- dataType datetime  timestam-->

                <el-table-column :label="$t('cip.dc.offlineDComission.type')" width="160">
                  <template slot-scope="scope">
                    <el-select
                      v-if="
                        scope.row.dataType == 'datetime' ||
                        scope.row.dataType == 'timestam'
                      "
                      clearable
                      v-model="scope.row.TimeType"
                      @change="changeTimeType($event, scope.row)"
                    >
                      <el-option :label="$t('cip.dc.offlineDComission.gdtime')" value="1"> </el-option>
                      <el-option :label="$t('cip.dc.offlineDComission.dttime')" value="2"> </el-option>
                    </el-select>

                    <el-select
                      v-if="
                        scope.row.dataType != 'datetime' &&
                        scope.row.dataType != 'timestam'
                      "
                      v-model="scope.row.QueryType"
                      clearable
                      @change="changeQueryType($event, scope.row)"
                    >
                      <el-option
                        v-for="(item, index) in queryTypeList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('cip.dc.dataobject.field.enterValue')"
                  prop="inputValue"
                  width="360"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="scope.row.QueryType && scope.row.QueryType !== '-1'"
                    >
                      <div v-if="scope.row.QueryType == '3'">
                        <el-input
                          v-model="scope.row.startValue"
                          :placeholder="$t('cip.dc.offlineDComission.star')"
                          style="width: 50%"
                        ></el-input>
                        <el-input
                          v-model="scope.row.endValue"
                          :placeholder="$t('cip.dc.offlineDComission.end')"
                          style="width: 50%"
                        ></el-input>
                      </div>
                      <el-input
                        v-if="scope.row.QueryType !== '3'"
                        v-model="scope.row.inputValue"
                      ></el-input>
                    </div>
                    <div v-else>
                      <el-date-picker
                        v-if="scope.row.TimeType == '1'"
                        v-model="scope.row.gdtime"
                        :end-placeholder="$t('cip.dc.DComission.enddate')"
                        :range-separator="$t('cip.dc.DComission.to')"
                        :start-placeholder="$t('cip.dc.DComission.startdate')"
                        style="width: 350px"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>

                      <el-select
                        v-if="scope.row.TimeType == '2'"
                        v-model="scope.row.DTime"
                      >
                        <el-option
                          v-for="item in DtimeType"
                          :key="item.value_name"
                          :label="item.value_name"
                          :value="item.value_code"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane :label="$t('cip.dc.offlineDComission.dygx')">
            <el-button
                    type="primary"
                    @click="Autody"
                    >{{ $t('cip.dc.offlineDComission.atuoMatching') }}</el-button
                  >
            <el-form-item label-width="1">
              <el-table
                ref="step2Table"
                :data="colmnList1"
                border
                size="small"
                style="width: 100%"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  :label="$t('cip.dc.dataobject.field.fieldName')"
                  prop="name"
                >
                </el-table-column>

                <el-table-column :label="$t('cip.dc.offlineDComission.filedzs')" prop="common">
                </el-table-column>

                <!-- dataType datetime  timestam-->

                <el-table-column :label="$t('cip.dc.offlineDComission.dygx')">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.sourceColumn" clearable filterable>
                      <el-option
                        :label="item.name"
                        :value="item.name"
                        v-for="(item, index) in step0SaveChecked"
                        :key="index"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>

    <el-dialog
      :title="$t('datacenter.dataQuality.monitoringTask.cycleSetting')"
      :visible.sync="cronDialogVisible"
    >
      <cron v-model="cron" class="cron"></cron>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cronDialogConfirm">{{
          $t("submitText")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import cron from "@/views/components/cron";
import { jxCron } from "@/api/dataAcquisition/index";
import {
  dataXBuildJson,
  dbListAll,
  getAiModelList,
  getColumn,
  getColumnsByQuerySql,
  syncDb,
  SaveOffline,
  relationEnum,
  UpdateOffline,
} from "@/api/dataAcquisition/offline";

export default {
  components: {
    HeadLayout,
    cron,
  },
  data() {
    return {
      activeNames: ["1", "2"],
seeTen:false,
      seeText:this.$t('cip.dc.offlineDComission.see'),
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
          loading: false,
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        },

        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-back",
          type: "button",
          icon: "",
          loading: false,
        },
      ],

      TimeTypeList: [
        {
          label: "yyyy-MM-dd HH:mm:ss",
          value: "yyyy-MM-dd HH:mm:ss",
        },

        {
          label: "yyyy-MM-dd",
          value: "yyyy-MM-dd",
        },

        {
          label: "MM/dd/yyyy HH:mm:ss",
          value: "MM/dd/yyyy HH:mm:ss",
        },
        {
          label: "HH:mm:ss",
          value: "HH:mm:ss",
        },
        {
          label: this.$t('cip.dc.offlineDComission.other'),
          value: "1",
        },
      ],
      addform: {
        sourceType:2,
        incrTimeFeild: "", //增量时间字段
        incrStartTime: "", //开始时间
        incrTimeFormat: "", //时间格式
        incrTimeFormat2: "", //其他时间格式
        name: "",
        readerDatasourceId: "",
        writerDatasourceId: "",
        readerTable: "",
        writerTable: "",
        cron: "",
        rdbmsReaderBo: {
          querySql: "",
          readerSplitPk: "",
        },
        rdbmsWriterBo: {
          postSql: "",
          preSql: "",
        },
        collectColumnList: [
          {
            sourceColumn: "",
            writerColumn: "",
          },
        ],
        collectWhereList: [
          {
            fileldId: "",
            fileldName: "",
            inputValue: "",
            queryType: "",
            startValue: "",
            endValue: "",
          },
        ],
        readerPareList: [],
      },
      cronDialogVisible: false,
      timeList: [],
      cron: "",
      step0SaveChecked: [],
      stepsActive: 0,
      showfull: false,
      rules1: {
        name: [{ required: true, message: this.$t('cip.dc.offlineDComission.rule1'), trigger: "blur" }],
        cron: [{ required: true, message: this.$t('cip.dc.offlineDComission.rule2'), trigger: "blur" }],
      },
      form: {
        readerDatasourceId: "", //步骤1数据库
        writerDatasourceId: "", //步骤2数据库
        readerTables: "", //步骤1表
        writerTables: "", //步骤2表
        readerColumns: [], //步骤1字段
        writerColumns: [], //步骤2字段
        rdbmsReader: {
          querySql: "", //1 sql
          readerSplitPk: "", //1 切分字段
          whereParams: "", //1where
        },
        rdbmsWriter: {
          preSql: "", //2sql,
          postSql: "", //2postSql
        },
      },
      queryTypeList: [
        { label: this.$t('cip.dc.offlineDComission.dengyu'), value: "1", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.budengyu'), value: "2", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.qujian'), value: "3", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.dayu'), value: "4", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaoyu'), value: "5", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.dadengyu'), value: "6", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaodengyu'), value: "7", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.baohan'), value: "8", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.bubaohan'), value: "9", type: "1" },
      ],

      step2Form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      step3Form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dbList: [],
      biaoList: [],
      biaoList1: [],
      colmnList: [],
      colmnList1: [],
      checkAll: false,
      checkedCities: [],
      // isIndeterminate: true,
      isIndeterminate: false,
      checkAll1: false,
      checkedCities1: [],
      // isIndeterminate1: true,
      isIndeterminate1: false,
      threeData: null,
      other: {
        shell: "",
        json: "",
      },
      disabled: true,
      disabled1: true,
    };
  },
  computed: {},
  methods: {
     changeSee(){
    this.seeTen = !this.seeTen
    if(this.seeTen){
      this.seeText = this.$t('cip.dc.offlineDComission.zhedie')
    }else{
      this.seeText = this.$t('cip.dc.offlineDComission.see')
    }
   },
    toback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    MjxCron(cron, count) {
      jxCron({ cron: cron, count: count }).then((res) => {
        this.timeList = res.data.data;
      });
    },
    cronDialogConfirm() {
      this.addform.cron = this.cron;
      this.cronDialogVisible = false;
      this.timeList = [];
      this.MjxCron(this.cron, 10);
    },
    send1(e) {
      var data = {
        shou: true,
        msg: "",
      };
      if (this.other.json) {
        data.msg = this.other.json;
        window.parent.postMessage(data, "*");
      } else {
        this.$message({
          message: this.$t('cip.dc.offlineDComission.getinfo'),
          type: "warning",
        });
      }
    },
    changeQueryType(e, a) {
      a.inputValue = "";
      a.startValue = "";
      a.endValue = "";
    },
    upSteps() {
      this.stepsActive--;
      if (this.stepsActive < 0) {
        this.stepsActive = 0;
      }
    },

    selectstep0Savetable() {
      console.log("wwwwwwww");
      //保存字段
      this.step0SaveChecked = this.$refs.step0Savetable.selection;
      this.addform.readerPareList = [];
      this.step0SaveChecked.forEach((element) => {
        this.addform.readerPareList.push(element.name);
      });
    },
    nextSteps() {},
    //获取数据库下拉
    getDbTableListFn() {
      dbListAll({}).then((res) => {
        this.dbList = res.data.data;
      });
    },
    dbChangeFn(value) {
      let source = this.dbList.find((item) => item.name == value);
      let obj = {
        host_id: source.name,
        dbType: source.dbtype,
        id: source.db_id,
      };
      this.form.readerTables = "";
      this.getAiModelListFn(obj);
    },
    dbChangeFn1(value) {
      let source = this.dbList.find((item) => item.name == value);
      let obj = {
        host_id: source.name,
        dbType: source.dbtype,
        id: source.db_id,
      };
      this.form.writerTables = "";
      getAiModelList(obj).then((res) => {
        this.biaoList1 = res.data.data;
      });
    },
    //根据数据库获取表
    getAiModelListFn(obj) {
      getAiModelList(obj).then((res) => {
        this.biaoList = res.data.data;
      });
    },
    getColumnFn(value) {
      let source = this.biaoList.find((item) => item.table_name == value);
      let obj = {
        dbtype: this.addform.readerDatasourceId,
        host_id: source.host_id,
        name: source.table_name,
      };
      this.dbList.forEach((element) => {
        if (element.name == this.addform.readerDatasourceId) {
          obj.dbtype = element.dbtype;
        }
      });
      getColumn(obj).then((res) => {
        this.colmnList = res.data.data;
        this.addform.sourceType = 2
        this.colmnList.forEach((item) => {
          this.$set(item, "inputValue", "");
          this.$set(item, "TimeType", "");
          this.$set(item, "gdtime", "");
          this.$set(item, "DTime", "");
          this.$set(item, "QueryType", "");
          this.$set(item, "startValue", "");
          this.$set(item, "endValue", "");
        });
      });
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.colmnList.map((item) => item.name) : [];
      console.log(this.checkedCities);
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colmnList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.colmnList.length;
    },
    getColumnFn1(value) {
      let source = this.biaoList1.find((item) => item.table_name == value);
      let obj = {
        dbtype: this.addform.writerDatasourceId,
        host_id: source.host_id,
        name: source.table_name,
      };
      this.dbList.forEach((element) => {
        if (element.name == this.addform.writerDatasourceId) {
          obj.dbtype = element.dbtype;
        }
      });
      getColumn(obj).then((res) => {
        this.colmnList1 = res.data.data;
        this.colmnList1.forEach((item) => {
          this.$set(item, "sourceColumn", "");
        });
      });
    },

    saveAll(e) {
     this.headBtnOptions[0].loading = true

      //保存字段
      this.addform.readerPareList = []
      let saveList = this.$refs.step0Savetable.selection;
      this.addform.readerPareList = [];
      saveList.forEach((element) => {
        this.addform.readerPareList.push(element.name);
      });
      console.log(saveList,"bbbbccccc");


      this.addform.collectColumnList = [];

      // 条件字段
      this.addform.collectWhereList = [];
      let tjchecked = this.$refs.step0Tjtable.selection;
      console.log(tjchecked,"tttjjj");
      tjchecked.forEach((element) => {
        this.addform.collectWhereList.push({
          fileldId: element.fileldId,
          fileldName: element.name,
          inputValue: element.inputValue,
          queryType: element.QueryType,
          startValue: element.startValue,
          endValue: element.endValue,
        });
      });

      let step2checked = this.$refs.step2Table.selection;
      step2checked.forEach((element) => {
        this.addform.collectColumnList.push({
          writerColumn: element.name,
          sourceColumn: element.sourceColumn,
        });
      });

      if (this.addform.collectColumnList.length > 0) {
        let canSave = true;
        this.addform.collectColumnList.forEach((element) => {
          if (!element.sourceColumn) {
            canSave = false;
          }
        });

        if (!canSave) {
          this.$message.warning(this.$t('cip.dc.offlineDComission.savetip'));
          this.headBtnOptions[0].loading = false
          return;
        }
      }

      let parms = {
        incrTimeFeild: this.addform.incrTimeFeild,
        incrStartTime: this.addform.incrStartTime,
        incrTimeFormat: this.addform.incrTimeFormat,
        name: this.addform.name,
        readerDatasourceId: this.addform.readerDatasourceId,
        writerDatasourceId: this.addform.writerDatasourceId,
        readerTable: this.addform.readerTable,
        writerTable: this.addform.writerTable,
        cron: this.addform.cron,
        rdbmsReaderBo: this.addform.rdbmsReaderBo,
        rdbmsWriterBo: this.addform.rdbmsWriterBo,
        collectColumnList: this.addform.collectColumnList,
        collectWhereList: this.addform.collectWhereList,
        readerPareList: this.addform.readerPareList,
        sourceType:this.addform.sourceType
      };

      //  incrTimeFeild: "", //增量时间字段
      //   incrStartTime: "", //开始时间
      //   incrTimeFormat: "", //时间格式
      //   incrTimeFormat2: "", //其他时间格式

      if (this.addform.incrTimeFormat == 1) {
        parms.incrTimeFormat = this.addform.incrTimeFormat2;
      }

      if (this.$route.query.id) {
        parms.id = this.$route.query.id;
        UpdateOffline(parms)
          .then((res) => {
            this.$message.success(this.$t('cip.dc.offlineDComission.editmsg'));
            this.headBtnOptions[0].loading = false
            if (e == "back") {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          })
          .catch((res) => {
            this.headBtnOptions[0].loading = false
          });
      } else {
        SaveOffline(parms)
          .then((res) => {
            this.$message.success(this.$t('cip.dc.offlineDComission.savemsg')
);
            if (e == "back") {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
            this.headBtnOptions[0].loading = false
          })
          .catch((res) => {
            this.headBtnOptions[0].loading = false
          });
      }
    },

  //匹配对应关系
    Autody(){
      this.step0SaveChecked.forEach(item1 => {
        this.colmnList1.forEach(item2 => {
          if(item1.name==item2.name){
            item2.sourceColumn = item1.name
          }
        });
      });
    },



    
    getColumnsByQuerySqlFn() {
      let obj = {
        querySql: this.addform.rdbmsReaderBo.querySql,
        datasourceId: this.addform.readerDatasourceId,
      };

      getColumnsByQuerySql(obj).then((res) => {
        this.$message.success(this.$t('cip.dc.offlineDComission.jieximsg'));
        this.$refs.step0Savetable.clearSelection();
        this.checkedCities = res.data.data;
        this.colmnList = res.data.data;
        this.addform.sourceType = 1
        // this.colmnList.forEach((element, index) => {
        //   this.checkedCities.forEach((element2) => {
        //     if (element.name == element2) {
        //       this.$refs.step0Savetable.toggleRowSelection(
        //         this.colmnList[index],
        //         true
        //       );
        //     }
        //   });
        // });

        setTimeout(() => {
          this.$refs.step0Savetable.toggleAllSelection(true)
          this.step0SaveChecked = this.$refs.step0Savetable.selection;
        }, 100);

        
       
      });
    },
  },
  created() {
    this.getDbTableListFn();
    // this.other = this.$route.query.other;
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      relationEnum({ id: this.$route.query.id }).then((res) => {
        this.addform = res.data.data;

        // this.addform.rdbmsWriterBo.preSql = sqlFormatter.format(this.addform.rdbmsWriterBo.preSql);
        //  this.addform.rdbmsWriterBo.postSql = sqlFormatter.format(this.addform.rdbmsWriterBo.preSql);
        if (res.data.data.incrTimeFormat) {
          let show2 = true;
          this.TimeTypeList.forEach((element) => {
            if (element.value == res.data.data.incrTimeFormat) {
              show2 = false;
            }
          });
          if (show2) {
            this.addform.incrTimeFormat2 = res.data.data.incrTimeFormat;
            this.addform.incrTimeFormat = "1";
          }
        }

        this.MjxCron(this.addform.cron, 10);
        dbListAll({}).then((res) => {
          this.dbList = res.data.data;

          let source = this.dbList.find(
            (item) => item.name == this.addform.readerDatasourceId
          );
          let obj = {
            host_id: source.name,
            dbType: source.dbtype,
            id: source.db_id,
          };

          getAiModelList(obj).then((res) => {
            this.biaoList = res.data.data;
            let source = this.biaoList.find(
              (item) => item.show_name == this.addform.readerTable
            );
            this.addform.readerTable = source.table_name;
            let obj = {
              dbtype: this.addform.readerDatasourceId,
              host_id: source.host_id,
              name: source.table_name,
            };

            this.dbList.forEach((element) => {
              if (element.name == this.addform.readerDatasourceId) {
                obj.dbtype = element.dbtype;
              }
            });

            getColumn(obj).then((res) => {
              this.colmnList = res.data.data;
              this.colmnList.forEach((item) => {
                this.$set(item, "inputValue", "");
                this.$set(item, "TimeType", "");
                this.$set(item, "gdtime", "");
                this.$set(item, "DTime", "");
                this.$set(item, "QueryType", "");
                this.$set(item, "startValue", "");
                this.$set(item, "endValue", "");
              });
              //回显保存字段
              this.$nextTick(() => {
                this.colmnList.forEach((element, index) => {
                  this.addform.readerPareList.forEach((element2) => {
                    if (element.name == element2) {
                      this.$refs.step0Savetable.toggleRowSelection(
                        this.colmnList[index],
                        true
                      );
                    }
                  });
                });
                this.colmnList.forEach((element, index) => {
                  this.addform.collectWhereList.forEach((element2) => {
                    if (element.name == element2.fileldName) {
                      element.inputValue = element2.inputValue;
                      element.QueryType = element2.queryType.toString();
                      element.startValue = element2.startValue;
                      element.endValue = element2.endValue;

                      this.$refs.step0Tjtable.toggleRowSelection(
                        this.colmnList[index],
                        true
                      );
                    }
                  });
                });
                this.$nextTick(()=>{
          if(this.addform.sourceType==1){
            this.getColumnsByQuerySqlFn()
          }
           })
              });
            });
          });

          let source2 = this.dbList.find(
            (item) => item.name == this.addform.writerDatasourceId
          );
          let obj2 = {
            host_id: source2.name,
            dbType: source2.dbtype,
            id: source2.db_id,
          };
          getAiModelList(obj2).then((res) => {
            this.biaoList1 = res.data.data;
            let source = this.biaoList1.find(
              (item) => item.table_name == this.addform.writerTable
            );
            let obj = {
              dbtype: this.addform.writerDatasourceId,
              host_id: source.host_id,
              name: source.table_name,
            };
            this.dbList.forEach((element) => {
              if (element.name == this.addform.writerDatasourceId) {
                obj.dbtype = element.dbtype;
              }
            });
            getColumn(obj).then((res) => {
              this.colmnList1 = res.data.data;
              this.colmnList1.forEach((item) => {
                this.$set(item, "sourceColumn", "");
              });

              this.$nextTick(() => {
                this.colmnList1.forEach((item, index) => {
                  this.addform.collectColumnList.forEach((element) => {
                    if (item.name == element.writerColumn) {
                      item.sourceColumn = element.sourceColumn;
                      this.$refs.step2Table.toggleRowSelection(
                        this.colmnList1[index],
                        true
                      );
                    }
                  });
                });
              });
            });
          });

         
          
        });
      });
    }

    this.step0SaveChecked = this.$refs.step0Savetable.selection;
    this.step0SaveChecked.forEach((element) => {
      this.addform.readerPareList.push(element.name);
    });
  },
};
</script>
<style></style>
